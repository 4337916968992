/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';

export default ({ data }) => {
  const [aboutMeImageApple, setAboutMeImageApple] = useState(
    data.contentfulLindseyAboutMeImages.aboutMeImages[0].fixed,
  );
  const [aboutMeImage, setAboutMeImage] = useState(
    data.contentfulLindseyAboutMeImages.aboutMeImages[0].fluid,
  );
  const [aboutMeImageAppleCounter, setAboutMeImageAppleCounter] = useState(0);
  const [aboutMeImageCounter, setAboutMeImageCounter] = useState(0);

  setTimeout(() => {
    if (
      aboutMeImageAppleCounter >=
      data.contentfulLindseyAboutMeImages.aboutMeImages.length
    ) {
      setAboutMeImageAppleCounter(0);
    } else {
      setAboutMeImageApple(
        data.contentfulLindseyAboutMeImages.aboutMeImages[
          aboutMeImageAppleCounter
        ].fixed,
      );
      setAboutMeImageAppleCounter(aboutMeImageAppleCounter + 1);
    }
  }, 4000);

  setTimeout(() => {
    if (
      aboutMeImageCounter >=
      data.contentfulLindseyAboutMeImages.aboutMeImages.length
    ) {
      setAboutMeImageCounter(0);
    } else {
      setAboutMeImage(
        data.contentfulLindseyAboutMeImages.aboutMeImages[aboutMeImageCounter]
          .fluid,
      );
      setAboutMeImageCounter(aboutMeImageCounter + 1);
    }
  }, 4000);

  const [isIOS, setIsIOS] = useState(true);
  const [isSafari, setIsSafari] = useState(true);

  useEffect(() => {
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;

    if (isSafari === true) {
      setIsSafari(true);
    } else {
      setIsSafari(false);
    }

    function iOS() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    }
    let appleTest = iOS();
    // let appleTest =
    //   /iPad|iPhone|iPod/.test(navigator.platform) ||
    //   (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (appleTest === true) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  }, []);

  return isIOS || isSafari ? (
    <div className="about__wrapperApple">
      <h1 className="about__headerApple"> About Me </h1>
      <div className="about__imageApple">
        <Image fixed={aboutMeImageApple}></Image>
      </div>
      <p className="about__bodyApple">
        Lindsey is an experienced TV and Radio presenter working across all of
        the major UK broadcast channels. A<strong> sassy Northerner</strong>{' '}
        with effortless charm and audience connection, Lindsey is a{' '}
        <strong>presenter with substance</strong>.
        <br />
        <br />
        Passionate about <strong> people, arts and the natural world</strong>,
        she’s tackled complex subjects from <strong>climate change</strong> to
        the power of poetry with honesty and flare.
        <br />
        <br />
        <div
          style={{
            fontStyle: 'italic',
            textAlign: 'center',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            color: '#45a29e',
          }}
        >
          "Lindsey is charming, witty, quick thinking, intelligent,
          knowledgeable, funny and very professional. A pretty rare combination!
          I always enjoy working with her." <strong>Chris Packham</strong>
        </div>
        <br />
        Lindsey is as happy <strong>live</strong>, unscripted and free-styling
        as she is crafting lines and pre-recording. Most of all, she loves
        interviewing and believes it is <strong>people and positivity</strong>{' '}
        that will help us transform the natural and creative worlds.
        <br />
        <br />
        What you don’t know is that Lindsey is also a keen sportswoman and
        self-confessed <strong>Netball fanatic</strong>. A natural
        &apos;Centre&apos;, commentating on the
        <strong> Netball World Cup Final</strong> for the BBC is one of her
        stand out moments.
        <br />
        <br />
        Lindsey is available for premieres, sports days and late-night bat
        hunts, all for a reasonable fee…cash preferred, payment in advance, no
        refunds &#128521;
      </p>
      <Link to="/credits" className="about__creditsLinkApple">
        Click here for a full list of credits
      </Link>

      <div className="about__leftApple"></div>
      <div className="about__rightApple"></div>
    </div>
  ) : (
    <div className="about__wrapper">
      <h1 className="about__header"> About Me </h1>
      <div className="about__image">
        <Image fluid={aboutMeImage}></Image>
      </div>
      <p className="about__body">
        Lindsey is an experienced TV and Radio presenter working across all of
        the major UK broadcast channels. A<strong> sassy Northerner</strong>{' '}
        with effortless charm and audience connection, Lindsey is a{' '}
        <strong>presenter with substance</strong>.
        <br />
        <br />
        Passionate about<strong> people, arts and the natural world</strong>,
        she’s tackled complex subjects from <strong>climate change</strong> to
        the power of poetry with honesty and flare.
        <br />
        <br />
        <div
          style={{
            fontStyle: 'italic',
            textAlign: 'center',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            color: '#45a29e',
          }}
        >
          "Lindsey is charming, witty, quick thinking, intelligent,
          knowledgeable, funny and very professional. A pretty rare combination!
          I always enjoy working with her." <strong>Chris Packham</strong>
        </div>
        <br />
        Lindsey is as happy <strong>live</strong>, unscripted and free-styling
        as she is crafting lines and pre-recording. Most of all, she loves
        interviewing and believes it is <strong>people and positivity</strong>{' '}
        that will help us transform the natural and creative worlds.
        <br />
        <br />
        What you don’t know is that Lindsey is also a keen sportswoman and
        self-confessed <strong>Netball fanatic</strong>. A natural
        &apos;Centre&apos;, commentating on the
        <strong> Netball World Cup Final</strong> for the BBC is one of her
        stand out moments.
        <br />
        <br />
        Lindsey is available for premieres, sports days and late-night bat
        hunts, all for a reasonable fee…cash preferred, payment in advance, no
        refunds &#128521;
      </p>
      <Link to="/credits" className="about__creditsLink">
        Click here for a full list of credits
      </Link>

      <div className="about__left"></div>
      <div className="about__right"></div>
    </div>
  );
};

export const pageQuery = graphql`
  query {
    contentfulLindseyAboutMeImages {
      aboutMeImages {
        title
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
        fixed(quality: 100, height: 500) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
`;
